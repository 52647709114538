












































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetDataTableReview } from "@/request/analysis";
@Component({})
export default class DataTableReview extends Vue {
  @Prop()
  private visible: any;
  @Prop()
  private tableId: any;
  private showData: any = false;
  private tableData: any = {};
  private tableHeader: any = {};
  private tableBody: any[] = [];
  private total: any = 0;
  private frontLength: any = 0;
  private get ifVisible() {
    return this.visible;
  }
  @Watch("report", { immediate: true, deep: true })
  private visibleChange(val: any) {}

  private set ifVisible(val) {
    this.$emit("update:visible", val);
  }
  /**
   * @description 关闭回调
   */
  private onClose() {
    this.$emit("close");
  }
  /**
   * @description 初始化
   */
  private mounted() {
    const params: any = {
      params: {
        table_id: this.tableId,
      },
    };
    GetDataTableReview(this, params).then((data: any) => {
      this.tableData = data;
      this.total = data.count;
      this.tableHeader = data.name;
      this.tableBody = data.data;
      if (this.tableHeader.back.length !== 0) {
        this.frontLength = this.tableHeader.front.length;
      }
    });
  }
}
