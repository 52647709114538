


























import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class extends Vue {
  @Prop()
  private ifShowSaveDialog: any;
  private get visible() {
    return this.ifShowSaveDialog;
  }
  private set visible(val) {
    this.$emit("update:ifShowSaveDialog", val);
  }
  @Prop()
  private message: any;
  private name: any = "";
  private messageChange() {
    this.$emit("update:message", this.message);
  }
  private cancel() {
    this.$emit("cancel");
  }
  /**
   * @description 确定保存
   */
  private save() {
    if (!this.name) {
      this.$message.warning("名称不能为空");
      return;
    }
    this.message["报告名称"] = this.name;
    this.$emit("save");
  }
}
